* {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  max-width: 100vw;
  overflow-x: hidden;
  background-color: #f9f1f0;
}
